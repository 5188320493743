import React, {useEffect} from 'react';
import Logo from '@app/components/ui/logo';
import {useRouter} from 'next/router';
import {useAppSelector} from '@app/store/hooks';
import {selectAuth} from '@app/store/auth/authSlice';
import {useLazyGetAuthUrlQuery} from "@app/store/login/api";
import cn from "classnames";
import Button from "@app/components/ui/button";
import {useTranslation} from "next-i18next";
import ProfileDropdown from "@app/components/ui/profile-dropdown";
import LocaleDropdownUi from "@app/components/ui/locale-dropdown-ui";
import {statusErrorMessageHandler} from "@app/utils/errorUtils";
import AnchorLink from "@app/components/ui/links/anchor-link";


function Navbar() {
    const router = useRouter();
    const auth = useAppSelector(selectAuth);
    const {t} = useTranslation(['common']);
    const [getAuthUrl, data] = useLazyGetAuthUrlQuery();
    const size = (router.pathname === "/" || router.pathname.includes("invitations")) ? "large" : "small";
    if (data.isError) {
        // @ts-ignore
        statusErrorMessageHandler(data?.error.originalStatus || data?.error.status, t)
    }

    useEffect(() => {
        if (data.isSuccess) {
            router.push(data.data.authorizationUrl);
        }
    }, [data.isSuccess]);

    const handleOnClick = async () => {
        try {
            await getAuthUrl(router.locale ?? "en");
        } catch (e: any) {
            console.error('error: ', e);
        }
    };
    return (
        <nav
            className={cn("!fixed top-0 !z-30 flex w-full items-center justify-between bg-white-200 ", size === "small" ? "h-[32px] !bg-blue-100 px-1" : "h-[72px] px-5  lg:px-[60px]")}>
            {router.pathname === "/" ? <AnchorLink href="/"><Logo/></AnchorLink> : <div></div>}
            <div className="flex items-center gap-2 md:gap-10">
                {
                    (router.pathname === "/" || router.pathname.includes("invitation")) && <LocaleDropdownUi/>
                }
                {router.pathname === "/" && (
                    <Button data-testid="signin-button" className="!rounded-[40px]" variant={'outline'}
                            shape={'rounded'}
                            onClick={handleOnClick}>
                        {t("signIn")}
                    </Button>
                )
                }
                {
                    auth.email !== '' && router.pathname.includes("invitation") && (
                        <ProfileDropdown isRoleVisible={false}/>
                    )
                }
            </div>
        </nav>
    );
}

export default Navbar;
